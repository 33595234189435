@mixin dsp($value) {
  display: $value;
}
.isRed {
  background-color: red;
  color: yellow !important;
}

.ArtistMain {
  .MuiPaper-root {
    background-color: transparent;
  }
  @include dsp(flex); //   display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // margin-left: 12px;
  // margin-right: 12px;

  //   justify-content: space-around;
}
@media screen and (max-width: 600px) {
  .ismusicimage {
    width: 100% !important;
    height: 150px !important;
  }
  .isVedioImage {
    width: 100% !important;
    height: 150px !important;
  }
  .isPodcastImage {
    width: 100% !important;
    height: 150px !important;
  }
}
.IsMusicard {
  width: fit-content;
  border-radius: 0px !important;
  background-color: rgba(18, 18, 21, 0.5) !important;
  color: rgb(255, 255, 255) !important;
  margin: 8px;
}
.ispodcastcard {
  width: fit-content;
  background-color: rgba(18, 18, 21, 0.5) !important;
  color: rgb(255, 255, 255) !important;
  margin: 8px;
}
.IsPodCastCardPage {
  width: fit-content;
  background-color: #181a20 !important;
  color: white !important;
  margin: 8px;
  padding: 8px;
  border-radius: 8px;
}
.isvediocard {
  width: fit-content;
  border-radius: 8px !important;
  background-color: #181a20 !important;
  color: rgb(255, 255, 255) !important;
  margin: 10px;
  padding: 8px;
}
.Timeadjust {
  float: right;
  position: absolute;
  margin-left: 127px;
  margin-top: 97px;
  border: 1px solid #18181d5c;
  background: #18181d5c;
  color: #4cc47c;
  opacity: 0.5;
  border-radius: 0px 0px 6px 0px;
  padding: 4px 12px;
  font-size: 10px;
  // backdrop-filter: blur(10px);
  backdrop-filter: invert(0.5);
}

.ismusicimage {
  width: 177px;
  height: 150px;
  object-fit: fill;
}
.isVedioImage {
  width: 180px;
  height: 120px;
  border-radius: 8px;
  object-fit: fill;
}
.isPodcastImage {
  width: 200px;
  height: 150px;
  border-radius: 8px;
  object-fit: fill;
}
.isPodcastImagetwo {
  width: 188px;
  height: 150px;
  border-radius: 8px;
}

.ArtistLabel {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  flex-direction: column;
  line-height: 18px;
  text-transform: capitalize;
  @include dsp(flex);
}
