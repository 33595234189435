.buttonAlign {
  padding: 6px;
  opacity: 0.6;
  font-size: 12px;
  margin: 8px;
  border-radius: 8px;
  background: #181a20;
  border: 1px solid #181a20;
}

.cardSetting {
  border-radius: 8px;
  background: #27262e;
  border: 1px solid rgba(161, 152, 180, 0.1);
  color: white;
  font-size: 15px;
  padding: 8px;
}

.UserCardSetting {
  border-radius: 8px;
  // background: #27262e;
  background: transparent;
  border: 1px solid rgba(161, 152, 180, 0.1);
  color: white;
  font-size: 15px;
}

//User Information Styles

.Follower_details {
  display: flex;
  text-align: Center;
  border: 1px solid #121216;
  margin-top: 10px;
  padding: 5px;
  border-radius: 8px;
  justify-content: center;
  background: #121216;
}

.Follower_part {
  // padding: 5px;
  padding: 1.2vh;
  font-size: 12px;
  color: white;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.paper-main {
  display: flex;
  background: #181a20;
  border-radius: 14px;
  // padding: 10px 0px;
}

.user-edit {
  .paper-main {
    justify-content: space-around;
  }
}

.backBlack {
  background: #121216;
}

.backLightGray {
  // background: #181a20;
  background: #27262e;
}

.backTransparent {
  background: transparent;
}