.pad-8 {
  padding: 8px;
}

.pad-10 {
  padding: 10px;
}

.pad-12 {
  padding: 12px;
}

.pad-14 {
  padding: 14px;
}

.pad-left-8 {
  padding-left: 8px;
}

.pad-left-10 {
  padding-left: 10px;
}

.pad-left-12 {
  padding-left: 12px;
}

.pad-left-14 {
  padding-left: 14px;
}

.pad-right-8 {
  padding-right: 8px;
}

.pad-right-10 {
  padding-right: 10px;
}

.pad-right-12 {
  padding-right: 12px;
}

.pad-right-14 {
  padding-right: 14px;
}

.pad-8-imp {
  padding: 8px !important;
}

.pad-10-imp {
  padding: 10px !important;
}

.pad-12-imp {
  padding: 12px !important;
}

.pad-14-imp {
  padding: 14px !important;
}

.pad-left-8-imp {
  padding-left: 8px !important;
}

.pad-left-10-imp {
  padding-left: 10px !important;
}

.pad-left-12-imp {
  padding-left: 12px !important;
}

.pad-left-14-imp {
  padding-left: 14px !important;
}

.pad-right-8-imp {
  padding-right: 8px !important;
}

.pad-right-10-imp {
  padding-right: 10px !important;
}

.pad-right-12-imp {
  padding-right: 12px !important;
}

.pad-right-14-imp {
  padding-right: 14px !important;
}

.mar-4 {
  margin: 4px;
}

.mar-top-10 {
  margin-top: 10px;
}

.mar-8 {
  margin: 8px;
}

.mar-10 {
  margin: 10px;
}

.mar-12 {
  margin: 12px;
}

.mar-14 {
  margin: 14px;
}

.mar-left-6 {
  margin-left: 6px;
}

.mar-left-4 {
  margin-left: 4px;
}

.mar-left-8 {
  margin-left: 8px;
}

.mar-left-10 {
  margin-left: 10px;
}

.mar-left-12 {
  margin-left: 12px;
}

.mar-left-14 {
  margin-left: 14px;
}

.mar-right-8 {
  margin-right: 8px;
}

.mar-right-10 {
  margin-right: 10px;
}

.mar-right-12 {
  margin-right: 12px;
}

.mar-right-14 {
  margin-right: 14px;
}

.mar-8-imp {
  margin: 8px !important;
}

.mar-10-imp {
  margin: 10px !important;
}

.mar-12-imp {
  margin: 12px !important;
}

.mar-14-imp {
  margin: 14px !important;
}

.mar-left-8-imp {
  margin-left: 8px !important;
}

.mar-left-10-imp {
  margin-left: 10px !important;
}

.mar-left-12-imp {
  margin-left: 12px !important;
}

.mar-left-14-imp {
  margin-left: 14px !important;
}

.mar-right-8-imp {
  margin-right: 8px !important;
}

.mar-right-10-imp {
  margin-right: 10px !important;
}

.mar-right-12-imp {
  margin-right: 12px !important;
}

.mar-right-14-imp {
  margin-right: 14px !important;
}

.add-artist-main {
  fieldset {
    border: 1px solid #757575 !important;
  }

  .add-artist-btn {
    .MuiButton-label {
      font-size: 13px !important;
    }
  }
}

// --------------------Image ------------------
.table-image {
  border-radius: 4px;
  margin-right: 8px;
  height: 25px;
  width: 25px;
}

.song-image {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin-right: 10px;
}

.image-20 {
  height: 20px;
  width: 20px;
}

.image-30 {
  height: 30px;
  width: 30px;
}

.image-40 {
  height: 40px;
  width: 40px;
}

.image-50 {
  height: 50px;
  width: 50px;
}

.profile-details-image {
  width: 90px;
  height: 90px;
}

.music-profile-image {
  width: 155px;
  height: 150px;
}

.video-profile-image {
  width: 168px;
  height: 120px;
  border-radius: 8px;
}

.video-card-style {
  width: fit-content;
  border-radius: 8px !important;
  background-color: #181a20 !important;
  color: white !important;
  margin: 10px;
  padding: 8px;
}

.music-card-style {
  width: fit-content;
  border-radius: 0px !important;
  background-color: rgba(18, 18, 21, 0.5) !important;
  color: white !important;
  margin: 8px;
}

.table-label-color-white {
  color: #a198b4;
  opacity: 0.5;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.42857143;
}

.table-cell {
  padding: 1px 1px;
  border-bottom: 1px solid rgba(139, 131, 152, 0.3);
}

// ------------------heading music
.heading-10 {
  font-size: 10px;
  opacity: 0.9;
}

.heading-13 {
  font-size: 13px;
  opacity: 0.9;
}

.heading-12 {
  font-size: 10px;
  opacity: 0.9;
}

.subheading-10 {
  font-size: 10px;
  opacity: 0.7;
}

.heading-8 {
  font-size: 8px;
  opacity: 0.7;
}

// -------------------display
.dis-flex {
  display: flex;
}

.dis-inline-row {
  display: inline-flex;
  flex-direction: row;
}

.dis-flex-column {
  display: flex;
  flex-direction: column;
}

.dis-flex-between {
  display: flex;
  justify-content: space-between;
}

.dis-flex-around {
  display: flex;
  justify-content: space-around;
}

// -----------Line-height------
.lineheight-28 {
  line-height: 28px;
}

.lineheight-18 {
  line-height: 18px;
}

.lineheight-15 {
  line-height: 15px;
}

.lineheight-22 {
  line-height: 22px;
}

// ------------header label-------------

.header-poppins-14 {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: #e6e2ef;
  opacity: 1;
  text-transform: capitalize;
}

.header-poppins-12 {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  letter-spacing: 0px;
  color: #e6e2ef;
  opacity: 1;
  text-transform: capitalize;
}

.header-type-3 {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  letter-spacing: 0px;
  color: #8b839b;
  opacity: 1;
}

// -------------opacity----------
.opacity-100 {
  opacity: 1;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-40 {
  opacity: 0.4;
}

//--------------------Text-color-----------

.yellowcolor {
  color: #ffd874;
}

.colorwhite {
  color: white;
}

//---------Background-color---------
.bg-black {
  background: rgba(18, 18, 21, 1);
}

// ------------font size---------
.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-8 {
  font-size: 8px;
}

.font-9 {
  font-size: 9px;
}

.font-14 {
  font-size: 18px;
}

.font-18 {
  font-size: 18px;
}

// -----------------color or background color

.background-black {
  background: #121216;
}

.background-black-light {
  background: #181a20;
}

.background-yellow-light {
  background: #ffd874;
}

.background-black-imp {
  background: #121216 !important;
}

.background-black-light-imp {
  background: #181a20 !important;
}

.background-yellow-light-imp {
  background: #ffd874 !important;
}

//--------content border
.addBorder {
  border: 1px solid rgba(161, 152, 180, 0.1);
  padding: 4px 8px;
  border-radius: 6px;
  // margin-top: 20px;
}

//Border-Radius
.border-radius-10 {
  border-radius: 10px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-12 {
  border-radius: 12px;
}

.border-radius-14 {
  border-radius: 14px;
}

// mainwindow color setup
.main-window {
  background: #121216;
  padding: 20px;
  color: white;
  height: auto;
  min-height: 100vh;
  overflow: hidden;
  // min-height: 800px;
  // overflow: hidden;
}

.main-window1 {
  background: #121216;
  padding: 20px;
  color: white;
  height: 100%;
  width: 100%;
  position: fixed;
  // min-height: 800px;
  // overflow: hidden;
}

// -------------------------------------loader Css
.loader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.text-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.NoDataFound {
  color: white;
}

.multiselect-close-icon {
  margin-left: 5px;
  /* color: red; */
  color: #ffd874;
  /* opacity: 0.9; */
}

.floating-label {
  position: relative;
  margin-bottom: 10px;
}

.floating-label .highlight {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 15%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

.profile-setting .floating-label {
  margin-bottom: 0;
}

.floating-input,
.floating-select {
  font-size: 14px;
  padding: 9px 4px;
  display: block;
  width: 100%;
  color: white;
  background-color: #181a20;
  border: none;
  border: 1px solid #757575;
  text-indent: 9px;
  border-radius: 5px;
}

.floating-input:focus,
.floating-select:focus {
  outline: none;
}

.floating-input:focus~label,
.floating-input:not(:placeholder-shown)~label {
  top: -10px;
  font-size: 12px;
  color: white;
  background-color: #181a20;
}

.floating-input:focus~.bar:before,
.floating-input:focus~.bar:after,
.floating-select:focus~.bar:before,
.floating-select:focus~.bar:after {
  width: 50%;
}

.floating-input:focus~.highlight,
.floating-select:focus~.highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

.profile-setting .floating-input,
.floating-select {
  padding: 4px 4px;
}

.floating-input,
.floating-select {
  font-size: 14px;
  padding: 9px 4px;
  display: block;
  width: 100%;
  color: white;
  background-color: transparent;
  border: none;
  border: 1px solid #757575;
  text-indent: 9px;
  border-radius: 5px;
}

.floating-input:focus,
.floating-select:focus {
  outline: none;
}

.floating-input:focus~label,
.floating-input:not(:placeholder-shown)~label {
  top: -10px;
  font-size: 12px;
  color: white;
  backdrop-filter: blur(1px);
  padding: 0px 6px;
  background-color: transparent;
  /* satyam changed this 09/08/2021 */
  /* background-color: black;  */
}

.floating-input:focus~.bar:before,
.floating-input:focus~.bar:after,
.floating-select:focus~.bar:before,
.floating-select:focus~.bar:after {
  width: 50%;
}

.floating-input:focus~.highlight,
.floating-select:focus~.highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

.hide {
  display: none;
}

.btn {
  display: inline-block;
  padding: 4px 12px;
  // margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  // color: #333333;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #ddd;
  // box-shadow: 2px 2px 10px #eee;
  border-radius: 4px;
}

.btn-large {
  // padding: 11px 19px;
  font-size: 17.5px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.upload-music-background {
  background-color: #181a20;
  padding: 50px;
}

.upload-background {
  border: 1px dotted grey;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
}

.upload-music-main {
  background-color: #181a20;
  padding: 50px;

  .user-profile {
    height: 70px;
    width: 70px;
  }

  .upload-text {
    font-size: 18px;
    color: #fff;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .upload-subtitle {
    font-size: 14px;
    color: #fff;
  }

  .fileContainer {
    background-color: transparent;
    padding: 0;
    margin: 0;

    .chooseFileButton {
      background-image: linear-gradient(#ffd874, #d8a318) !important;
      border-color: #ffd874 !important;
      color: rgb(0, 0, 0) !important;
      border-radius: 5px !important;
      padding: 6px 23px !important;
    }

    img {
      display: none !important;
    }
  }
}

.upload-song {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  overflow: hidden auto;

  .MuiGrid-spacing-xs-3 {
    padding: 0 !important;
  }

  .login-sub-title {
    font-size: 13px;
    color: #a198b4;
  }
}

.selection-page {
  border: 1px solid #757575;
  padding: 0px 4px 4px 14px !important;
  border-radius: 5px;
  padding-left: 13px;
  padding-top: 11px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;

  .MuiFormControlLabel-root {
    margin: 0 !important;
  }
}

.upload-control {
  .MuiGrid-spacing-xs-3 {
    padding: 12 !important;
  }
}

.add-form-checkbox {
  .MuiFormControl-marginDense {
    margin: 0 !important;
    padding-right: 10px !important;
  }

  .MuiCheckbox-root {
    color: rgb(255, 216, 116);
  }

  .MuiCheckbox-colorSecondary {
    padding: 0 !important;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: rgb(255, 216, 116);
  }
}

.mb-10 {
  margin-bottom: 10px !important;
}

.add-artist-main {
  .search-wrapper {
    border: 1px solid #757575 !important;
    border-radius: 5px !important;
    max-height: 134px !important;
    overflow-y: auto !important;

    .searchBox {
      color: #fff !important;
      margin-left: 10px !important;
      font-size: 14px !important;
    }

    i {
      font-size: 16px !important;
    }
  }

  .floating-input {
    background-color: #121216;

    .option {
      background-image: linear-gradient(#ffc83a, #bd8900) !important;

      &:hover {
        background-image: linear-gradient(#ffc83a, #bd8900) !important;
      }
    }
  }

  .chip {
    background-image: linear-gradient(#ffc83a, #bd8900) !important;
  }

  .optionListContainer {
    background-color: #121216;

    .highlight {
      background-color: transparent !important;
      top: 0 !important;
      // display: none;
    }

    li:hover {
      background: #ffd874 !important;
    }

    li {}
  }
}

.mt-9 {
  margin-top: 9px;
}

option:hover {
  background-image: linear-gradient(#ffc83a, #bd8900) !important;
}

.MuiInputLabel-shrink {
  transform-origin: top left !important;
}

.add-artist-btn {
  .MuiButton-root {
    color: rgb(0, 0, 0) !important;
    background-image: linear-gradient(#ffd874, #d8a318) !important;
    border-color: #ffd874 !important;
  }
}

.h-100 {
  height: 100% !important;
}

.upload-song-admin {
  padding: 10px 20px;
  background-color: #121216;
}

.music-cover {
  height: 50px;
  width: 50px;
}

.song-details {
  font-size: 14px;
  color: #fff;
  margin-bottom: 5px;
  padding-left: 5px;
}

.song-time {
  font-size: 14px;
  color: #fff;
  padding-left: 5px;
}

.upload-song-list {
  max-height: 350px;
  overflow: auto;
  min-height: 350px;
}

.close-btn {
  height: 25px;
  width: 25px;
}

.uploaded {
  display: flex;
  align-items: center;
}

.text-center {
  text-align: center;
}

.artist-load {
  .MuiTableContainer-root {
    max-height: 400px !important;
  }
}

.fieldset {
  border-color: #757575 !important;
}

.upload-news {
  max-height: 310px;
  overflow-y: auto;
  min-height: 337px;
  overflow-x: hidden;
}

.react-datepicker-wrapper {
  input {
    background-color: transparent !important;
    border-color: #757575 !important;
    border-radius: 5px !important;
    height: 38px !important;
    border: 1px solid #757575 !important;
    width: 100% !important;
    margin: 10px 0 !important;
    color: #fff;
    padding-left: 10px;

    :focus-visible {
      border: transparent !important;
      outline: transparent !important;
    }
  }
}

.add-artist-overflow {
  overflow-y: auto;
  max-height: 700px;
  overflow-x: hidden;
}

.errorText {
  color: red !important;
}

.NoDataFound_center {
  text-align: center;
}

.browse-btn {
  border: 1px solid #ffd874 !important;
  border-radius: 5px;
  color: #ffd874 !important;
  padding: 3px 5px;
  margin-top: 5px;
  cursor: pointer;
}

.d-flex-space-between {
  display: flex;
  justify-content: space-between;
}

.complete-profile {
  .errorText {
    margin-top: 5px !important;
  }
}

.browse-sec {
  .upload-song-admin {
    margin: 5px 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1700px) {
  .paper-main {
    .edit-user-btn {
      bottom: 1px !important;
      left: 85px !important;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .upload-song {
    padding: 15px;
  }

  .upload-music-main {
    padding: 15px;
  }

  .mt-20 {
    margin-top: 10px !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .browse-btn {
    margin-left: 220px;
    margin-bottom: 5px;
  }

  .upload-background {
    padding: 50px 0;
  }

  .paper-main {
    .edit-user-btn {
      bottom: 8px !important;
      left: 80px !important;
    }
  }
}

.paper-main {
  position: relative;

  .edit-image {}

  .edit-user-btn {
    position: absolute;
    bottom: 5px;
    left: 130px;
  }
}

.create-btn {
  .MuiButton-outlined {
    max-width: 100px;
    cursor: pointer;
  }
}

.upload-poocaste {
  .upload-background {
    padding: 130px 0;
  }
}

.custom_foll {
  .followerOption {
    width: 100%;
  }
}

.removeBackgroundColor {
  .floating-input {
    background-color: #181a20;
  }
}


.ck.ck-editor {
  color: #121216 !important;
}


.orderDropDrown {
  .floating-input {
    background-color: #181a20;
    text-indent: 0px;
    border: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

.MuiOutlinedInput-input {
  padding: 15px !important;
}

.MuiInputBase-input {
  font-size: 14px !important;
}

.MuiFormControl-fullWidth {
  margin-bottom: 15px !important;
  margin-top: 0 !important;
}

.MuiDialog-container {
  .MuiDialogContent-root {
    padding: 8px 13px;
  }

  .MuiGrid-spacing-xs-2 {
    width: 100% !important;
  }
}

.ck-content {
  height: 550px !important;
}

.support-table {
  tr td:nth-child(1) {
    width: 350px !important;
  }

  tr td:nth-child(2) {
    width: 300px !important;
  }
}

.country-search-bar {
  .MuiInputBase-input {
    padding: 9px 0 !important;
  }
}