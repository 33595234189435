@mixin fontSz($value) {
  font-size: $value;
}

.podcastdetail {
  line-height: 14px;
  // padding-left: 16px;
  // padding-bottom: 16px;
  padding-right: 8px;
  @include fontSz(10px);
  opacity: 0.4;
  text-align: justify;
}
