* {
  margin-block: auto;
}
.custom-image-email {
  border: 1px solid #27262e;
  // margin: 12px;
  border-radius: 16px;
  padding: 20px 0px;
  margin-top: 60px;
}
.Header {
  // justify-content: center;
  text-align: center;
}
.image-setting {
  height: 120px;
  width: 120px;
  margin-top: -24%;
  border-radius: 16px;
}

.Data-Set {
  border: 1px solid rgb(52, 49, 60);
  padding: 8px;
  border-radius: 16px;
  margin: 12px;
}
.follower {
  display: flex;
  // margin: 12px;
  display: flex;
  border: 1px solid #34313c;
  padding: 16px;
  border-radius: 16px;
  background-color: #121216;
}
.followerOption {
  // justify-content: center;
  width: 50%;
  text-align: center;
}
// p {
// }
