.ImageUpload {
  text-align: center;
  padding: 30px 0px;
  // border: 1px #ffd874;
  /* border-style: dashed; */
  border-radius: 4px;
  animation: auto;
  border: dashed 2px #ffd874;
  stroke-linecap: round;
  stroke-dasharray: 21%;
}
