// .MuiFormControl-root {
//   width: 100%;
 
//   color: white !important;
// }
// .MuiFormLabel-root {
//   color: whitesmoke !important;
//   font-size: 0.7rem !important;
// }
// .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
//   border-color: #52536d !important;
//   border-width: 1px !important;
//   color: whitesmoke !important;
// }
// .MuiOutlinedInput-input {
//   color: #e6e2ef !important;
// }
// .Mui-focused {
//   color: #52536d !important;
// }
// .MuiOutlinedInput-inputMarginDense {
//   padding-top: 5.5px !important;
//   padding-bottom: 10.5px !important;
// }
// .MuiOutlinedInput-root.Mui-focused {
//   border-color: #52536d !important;
//   border-width: 1px !important;
//   color: #52536d;
// }
// .MuiOutlinedInput-notchedOutline {
//   border-color: #8186af !important;
//   border-width: 1px !important;
// }
// chip
.MuiChip-label {
  color: #8b839b;
}
.MuiChip-root {
  border-radius: 12px !important;
  background-color: #121216 !important;
  font-size: 0.6rem !important;
  padding: 4px !important;
}
.MuiChip-deleteIcon {
  color: #ffd874 !important;
}
// .MuiAutocomplete-option[data-focus="true"] {
//   background-color: #121216 !important;
// }
// button
// .MuiButton-root {
//   width: 80% !important;
//   margin-left: 5% !important;
// }
//svg button

.MuiSvgIcon-root {
  height: 18px !important;
  width: 18px !important;
}
// .MuiSvgIcon-root {
//   border-radius: 60px;
//   background: #ffd874 !important;
// }
.Component-closeButton-10 {
  color: black !important;
}

//MuiTab
.MuiTab-root {
  min-width: 0px !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
  font-weight: initial !important;
  box-sizing: unset !important;
  // background: rgba(90, 100, 129, 1) !important;
  color: rgba(255, 255, 255, 0.7) !important;
  min-height: 2px !important;
}

//Tab Indicator
.PrivateTabIndicator-root-9 {
  // background-color: !important;
  height: 0px !important;
}
.MuiTab-textColorInherit.Mui-selected {
  color: rgba(255, 216, 116, 1) !important;
  font-size: 15px !important;
  font-weight: 200 !important;
}
.PrivateTabIndicator-colorSecondary-18 {
  background-color: rgba(255, 216, 116, 1) !important ;
  width: 24px !important;
  margin-left: 10px;
}
// card
.MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}

//dialog
.MuiDialog-paper {
  border: radius 16px !important;
  background: #181a20 !important;
  color: white !important;
}
.MuiPaper-rounded {
  // border-radius: 20px !important;
  // background: #181a20 !important;
}
.MuiDialog-paperScrollPaper {
  // background: #181a20 !important;
}
.MuiPaper-root {
  // background-color: #121216 !important;

  box-shadow: none !important;
}
.MuiMenu-list {
  background-color: #121216 !important;
}
// box
// .MuiBox-root-22 {
//   padding: 10px 0px 10px 0px !important;
// }
// .MuiBox-root-30 {
//   padding: 10px 0px 10px 0px !important;
// }
// .MuiBox-root-24 {
//   padding: 10px 0px 10px 0px !important;
// }
// .MuiBox-root-26 {
//   padding: 10px 0px 10px 0px !important;
// }
// .MuiBox-root-28 {
//   padding: 10px 0px 10px 0px !important;
// }
// .MuiBox-root-30 {
//   padding: 10px 0px 10px 0px !important;
// }
// .MuiBox-root-32 {
//   padding: 10px 0px 10px 0px !important;
// }

.MuiDialog-paperWidthXs {
  max-width: 420px !important;
  padding: 10px 5px !important;
}
// tab

.PrivateTabIndicator-colorSecondary-19 {
  background-color: #ffd874 !important;
  opacity: 0.7 !important;
  width: 20px !important;
  // left: 10px !important;
}
.PrivateTabIndicator-colorSecondary-9 {
  background-color: #ffd874 !important;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #ffd874 !important;
}
.MuiDrawer-paper {
  display: block !important;
}
.MuiDrawer-paperAnchorDockedLeft {
  border-right: 1px solid rgba(0, 0, 0, 0.7) !important;
}
// .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {

//   background-color: #ffd874 !important;
// }
