.headingLogo {
  font-size: 30px;
  color: white;
  text-align: center;
  margin-top: 20px !important;
}
.subHeading {
  font-family: Futura PT;
  font-size: 0.9rem !important;
  // margin-top: 18px;
  // padding-top: 18px;

  font-weight: book;
  color: #8b8398;
}
// .Navbar {
//   background-color: red !important;
// }
/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 600px) {
  .Navbar {
    display: none;
  }
}
.SideBarData {
  height: auto;
  padding: 0px;
  width: 100%;
}
.SideBarData .row {
  width: 100%;
  height: 40px;
  list-style-type: none;
  font-family: Poppins;
  font-weight: regular;
  font-size: 13px;
  margin: 0%;
  flex-direction: row;
  color: #675f75;
  justify-content: center;
  align-items: center;
  display: flex;
  // font-size: 10px;
  /* border: 1px solid black; */
  /* border-radius: 10px; */
}

.activeData {
  background-image: linear-gradient(
    to right,
    rgba(255, 216, 116, 0.3),
    rgba(216, 163, 24, 0.01)
  );
  font-size: 14px;
  height: 35px;
  border-left: 4px solid #ffd874;
  color: white !important;
}
.row {
  height: 35.8px !important;
}
.SideBarData .row:hover {
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    rgba(255, 216, 116, 0.3),
    rgba(216, 163, 24, 0.01)
  );
  border-left: 4px solid #ffd874;
  color: white !important;
}
/* .SideBarData .row #icon:hover {
    color: #fdd2da;
  }
  .SideBarData #title:hover {
   
  } */
.row #icon {
  flex: 35%;
  display: grid;
  place-items: center;
}
.row #title {
  flex: 65%;
  // font-size:"13px"
}

#title:active {
  color: black;
}
