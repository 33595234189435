.cardBoxView {
  text-align: center;
}
.textRound {
  padding: 4px 10px;
  background: white;
  font-weight: bold;
  font-size: 15px;
  border-radius: 15px;
  color: black;
}
