.Data-Set {
  // width: 50%;
  border: 1px solid #34313c;
  padding: 8px;
  border-radius: 16px;
  margin: 4px 12px;
  background-color: #121216;
  padding-right: 10rem;
  //   padding-bottom: 0px;
}
.ViewData {
  margin-left: 10px;
  color: white;
}
.ViewImage {
  height: 40px;
  width: 40px;
  border-radius: 8px;
}
