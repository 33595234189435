.wrapper {
  max-height: 137px;
  // min-width: 340px;
  //   border: 1px solid red;
  display: flex;
  overflow-x: hidden;
}
@media screen and (max-width: 600px) {
  .mobileWidth {
    width: 340px;
    // overflow
  }
}

.wrapper::-webkit-scrollbar {
  width: 0px !important;
}
.wrapper-2::-webkit-scrollbar-track {
  border: 1px solid #000 !important;
  padding: 2px 0 !important;
  background-color: red !important;
}
.wrapper .item {
  min-width: 300px;
  height: max-content;
  // line-height: 110px;
  text-align: center;
  max-width: 300px;
  margin-right: 2px;
}
.item-heading {
  // padding-top: 10px;
  line-height: 15px;
  text-align: left;
}
// .card-2 {
//   //   min-width: 110px;
//   //   height: 120px;
//   line-height: 110px;
//   text-align: center;
//   margin-right: 2px;
// }
