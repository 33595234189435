.NavSearch {
  display: none;
}
.textTransformImp {
  text-transform: inherit !important;
}
@media screen and (max-width: 600px) {
  .NavSearch {
    display: contents;
    margin-right: 10px;
  }
  #UserText {
    align-items: center;
  }
}
