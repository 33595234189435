.MusicBody {
  border-radius: 8px;
  background: transparent;
  border: 1px solid rgba(161, 152, 180, 0.1);
  color: white;
  line-height: 18px;
  padding: 10px;
}
.Musiclabel {
  font-size: 8px;
  opacity: 0.4;
}
